import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a className="sel" href="/kontakt/">
                    Kontakt
                  </a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/kontakt/" className="sel">
              {" "}
              &gt; Kontakt
            </a>
          </div>
          <div
            id="page_content"
            style={{
              "line-height": "20px"
            }}
          >
            Lubimy, gdy nasi klienci się do nas odzywają. Skontaktuj się w
            sprawie uwag, pytań i opinii na temat naszych produktów.
            <br />
            <br />
            JNTL Consumer Health (Poland) sp. z o.o.
            <br />
            ul. Iłżecka 24
            <br />
            02-135 Warszawa
            <br />
            Tel.: +48 (22) 237 80 00
            <br />
            Fax: +48 (22) 237 80 01
            <br />
            <br />
            Proszę kliknąć{" "}
            <a
              href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p4"
              target="_blank"
            >
              tutaj
            </a>
            , aby skontaktować się z nami przez pocztę elektroniczną.
            <br />
            Infolinia: pod numerem 00 800 555 22 000
            <br />
            (pon.- pt. w godz. 9-17, opłata wg taryfy operatora)
            <br />
            Inspektor Ochrony Danych w UE ds Kenvue: Cristina del Val Gómez
            <br />
            e-mail: <a href = "mailto: emeaprivacy@kenvue.com">Kenvue EMEA Privacy</a>
          </div>
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
